<template>
  <c-flex flex-direction="column">
    <h3 v-chakra font-weight="500" font-size="12px" color="#333333">
      {{ title }}
    </h3>
    <hr v-chakra d="block" mt="7px" mb="17px" />
    <c-list
      v-if="items.length > 0"
      :d="[null, 'flex']"
      :flex-wrap="[null, 'wrap']"
      v-chakra="{
        gap: [null, '10px 20px'],
      }"
    >
      <c-list-item
        v-for="item in items"
        :key="item.id"
        :w="['100%', '386px']"
        :h="['64px', '112px']"
        bg="white"
        border="1px solid #F2F2F2"
        box-shadow="0px 5px 30px rgba(0, 0, 0, 0.05)"
        border-radius="6px"
        d="flex"
        align-items="center"
        justify-content="space-between"
        margin-bottom="6px"
        px="22px"
      >
        <c-flex
          :flex-dir="['row', 'column']"
          :align-items="['center', 'flex-start']"
          :justify-content="['space-between', null]"
          flex="1"
        >
          <c-text font-weight="600" font-size="14px" color="#111">
            {{ item.name }}
          </c-text>
          <c-text
            v-if="item.isEditable"
            :mt="[null, '10px']"
            color="brand.900"
            font-weight="500"
            font-size="12px"
          >
            Edit
          </c-text>
        </c-flex>
        <c-flex
          align-items="center"
          @click="$emit('click', item)"
          :cursor="$listeners.click ? 'pointer' : ''"
        >
          <c-image
            :d="['block', 'none']"
            :src="require('@/assets/icon-chevron-right.svg')"
            alt="icon arrow right"
          />
          <c-image
            :d="['none', 'block']"
            :src="require('@/assets/icon-chevron-right-grey.svg')"
            alt="icon arrow right"
          />
        </c-flex>
      </c-list-item>
    </c-list>
  </c-flex>
</template>

<script>
export default {
  name: "data-diet-menu",
  props: ["title", "items"],
};
</script>

<style scoped></style>
