var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-box',{attrs:{"w":"full"}},[_c('c-flex',{attrs:{"flex-direction":"column"}},[_c('h1',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"font-weight":"bold","font-size":"18px","color":"#111111","mt":"24px"}},[_vm._v(" Data Diet ")]),_c('c-flex',{attrs:{"flex-direction":"column","mt":"24px","w":"full"}},[(_vm.kuisioners)?_c('data-diet-menu',{attrs:{"title":"History Kuisioner","items":_vm.kuisioners},on:{"click":function($event){return _vm.$router.push({
            name: 'client.profile.detail-quizionary',
            params: { programId: $event.programId, isEditable: $event.isEditable },
            meta: { isEditable: $event.isEditable },
          })}}}):_vm._e(),(_vm.historyMealPlan.length > 0)?_c('data-diet-menu',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"title":"History Meal Plan","items":_vm.historyMealPlan,"mt":"42px"},on:{"click":function($event){return _vm.$router.push({
          name: 'meal-plan.with-id',
          params: { clientId: _vm.clientId },
          query: { programId: $event.programId },
        })}}}):_vm._e(),(_vm.historyProgressTracker.length > 0)?_c('data-diet-menu',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"title":"History Progress & Tracker","items":_vm.historyProgressTracker,"mt":"42px"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }